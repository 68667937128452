// @flow

import React from "react";
import {
    withPrivateRoute,
    Layout,
    AdminUserManagementLayout,
    ExternalGroups,
} from "@containers";

/**
 * Admin Gebruikers Beheer - Externe Groepen
 */
const AdminExternalGroups = (props: *) => {
    return (
        <Layout {...props}>
            <AdminUserManagementLayout>
                <ExternalGroups id="external-groups" />
            </AdminUserManagementLayout>
        </Layout>
    );
};

export default withPrivateRoute(AdminExternalGroups);
